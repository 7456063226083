import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/nextjs";
import { Fragment, useState } from "react";

import { useSession } from "../providers/session";

import { currency } from "utils/formats";
import Spinner from "../assets/spinner.svg";
import { PaymentMethods } from "./methods";
import OnlineCourse, { Entry } from "./online-course";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function OnlineBookingCheckout({ open, setOpen }: Props) {
  const { onlineCourses } = useSession();

  const [state, setState] = useState<{
    loading: boolean;
    error: any;
  }>({
    loading: false,
    error: null,
  });

  const total = calculateTotal(onlineCourses);

  const priceIds = onlineCourses.map((course) => course.fields.pricing.id);

  const handleSubmit = async () => {
    setState((s) => ({ ...s, loading: true }));

    try {
      gtag("event", "begin_checkout", {
        event_callback: function () {},
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }

    try {
      const req = await fetch("/api/e-learning/create-checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceIds,
        }),
      });
      const res = await req.json();
      console.log(res);
      window.location.href = res.url;
    } catch (err) {
      setState((s) => ({ ...s, loading: false }));
      console.log(err);
      Sentry.captureException(err);
      setState((s) => ({ ...s, loading: false }));
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <section
                      className="min-h-screen mx-auto fixed right-0 top-0 bottom-0 z-50 bg-white"
                      id="booking"
                    >
                      <h1 className="sr-only">Checkout</h1>
                      <div className="flex flex-col h-[100vh]  overflow-hidden">
                        <section
                          id="order-summary"
                          className="flex-1 px-4 pt-4 w-full h-full flex flex-col lg:px-8 pb-4 mx-auto border shadow"
                        >
                          <div className="flex justify-between py-2">
                            <h2 className="text-2xl mb-2 font-medium">
                              Order Summary
                            </h2>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-col xl:max-w-4xl flex-1 overflow-scroll pt-4">
                            <div className="flow-root flex-1">
                              {(!onlineCourses ||
                                (onlineCourses &&
                                  onlineCourses.length === 0)) && (
                                <div className="flex flex-col space-y-4">
                                  <p>
                                    You have not added any online courses to
                                    your basket
                                  </p>
                                  <div className="">
                                    <button
                                      type="button"
                                      className="btn-secondary"
                                      onClick={() => setOpen(false)}
                                    >
                                      Continue browsing
                                    </button>
                                  </div>
                                </div>
                              )}
                              {onlineCourses && (
                                <>
                                  <ul
                                    role="list"
                                    className="-my-6 divide-y divide-gray-200"
                                  >
                                    {onlineCourses.map((onlineCourse, i) => {
                                      return (
                                        <li key={`service-${i}`}>
                                          <OnlineCourse
                                            onlineCourse={onlineCourse}
                                            itemIndex={i}
                                          />
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="">
                            {onlineCourses && onlineCourses.length > 0 && (
                              <>
                                {/* <Voucher items={items} /> */}
                                <dl className="text-sm font-medium text-gray-500">
                                  <div className="flex flex-col  text-gray-900 pt-4">
                                    <dt className="text-base flex justify-between">
                                      <span>Total</span>
                                      <span>{currency(total)}</span>
                                    </dt>
                                  </div>
                                </dl>
                              </>
                            )}
                            {onlineCourses.length > 0 ? (
                              <div className="flex flex-col justify-between mt-2 pb-12 sm:pb-0">
                                <button
                                  disabled={state.loading}
                                  type="button"
                                  onClick={() => handleSubmit()}
                                  className="cursor-pointer block w-full rounded-md bg-brand-600 px-6 py-4 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 outline outline-2 outline-offset-2 outline-brand-600 gradient-border transition-colors duration-200 hover:outline-brand-500 text-center"
                                >
                                  {!state.loading && (
                                    <span className="relative z-[1] text-center">
                                      Book Now
                                    </span>
                                  )}
                                  {state.loading && (
                                    <span className="relative z-[1] text-center">
                                      Processing
                                    </span>
                                  )}
                                  {state.loading && (
                                    <span className="absolute right-1 z-[1]">
                                      <Spinner className="w-9 h-9" />
                                    </span>
                                  )}
                                </button>
                              </div>
                            ) : null}
                            <PaymentMethods />
                          </div>
                        </section>
                      </div>
                    </section>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function calculateTotal(courses: Entry[]): number {
  if (courses && courses.length > 0) {
    return courses?.reduce((total, course) => {
      const amount = course.fields.pricing?.unit_amount || 0;
      return total + amount;
    }, 0);
  } else {
    return 0;
  }
}
